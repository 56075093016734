<template>
    <dashboard-core-view />
</template>

<script>
export default {
  name: "TraceView",

  components: {
    DashboardCoreView: () => import("./components/core/View"),
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
